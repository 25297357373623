import './css/about.css';

import AboutImg from './images/aboutimg.webp';

function About(){
    return(
        <>
            <section className='about'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='about-img'>
                                <h3> <span> 7+ </span> Products Brands We Have </h3>

                                <img loading='lazy' src={AboutImg} alt='building_tools' />
                                <div className='number'>
                                    <h1> 12 + </h1>
                                    <h2> Years of Experince </h2>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-2 col-md-2 col-sm-6 col-12'>
                            <div className='about-content'>
                                <i className="las la-plug"></i>
                                <h1> Electrical </h1>
                                <p> Discover a wide array of electrical products that adhere to the highest quality standards. From essentials to cutting-edge innovations </p>
                                <a href="/">
                                    Contact Us 
                                    <i className="las la-long-arrow-alt-right"></i>
                                </a>
                            </div>
                        </div>

                        <div className='col-lg-2 col-md-2 col-sm-6 col-12'>
                            <div className='about-content'>
                                <i className="las la-wrench"></i>
                                <h1> Plumbing </h1>
                                <p> Our diverse collection of plumbing accessories ensures you have access to premium products that deliver on performance and durability </p>
                                <a href="/">
                                    Contact Us 
                                    <i className="las la-long-arrow-alt-right"></i>
                                </a>
                            </div>
                        </div>




                        <div className='col-lg-2 col-md-2 col-sm-6 col-12'>
                            <div className='about-content'>
                                <i className="las la-tools"></i>
                                <h1> Power Tools </h1>
                                <p>  Elevate your workmanship with our comprehensive range of power tools. Crafted to meet rigorous quality benchmarks, </p>
                                <a href="/">
                                    Contact Us 
                                    <i className="las la-long-arrow-alt-right"></i>
                                </a>
                            </div>
                        </div>


                        <div className='col-lg-2 col-md-2 col-sm-6 col-12'>
                            <div className='about-content'>
                                <i className="las la-hard-hat"></i>
                                <h1> Hardware </h1>
                                <p> Explore a plethora of hardware accessories that exemplify quality craftsmanship. Whether it's for construction, maintenance, or enhancement </p>
                                <a href="/">
                                    Contact Us 
                                    <i className="las la-long-arrow-alt-right"></i>
                                </a>
                            </div>
                        </div>


                        <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
                            <div className='about-contact'>
                                <h1> Want To <span> Know More </span> CALL  <a href="tel:+9714267881"> +9714267881 </a> </h1>
                            </div>
                        </div>



                    </div>
                </div>
            </section>
        </>
    )
}

export default About;