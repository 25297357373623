import './css/benefits.css';


import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-cube';

import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,EffectCube,EffectCoverflow,Navigation,Pagination} from 'swiper';

import Partner1 from './images/partners/b1.jpg';
import Partner2 from './images/partners/b2.jpg';
import Partner3 from './images/partners/b3.jpg';
import Partner4 from './images/partners/b4.jpg';
import Partner5 from './images/partners/b5.jpg';
import Partner6 from './images/partners/b6.jpg';
import Partner7 from './images/partners/b7.jpg';
import Partner8 from './images/partners/b8.jpg';
import Partner9 from './images/partners/b9.jpg';
import Partner10 from './images/partners/b10.jpg';
import Partner11 from './images/partners/b11.jpg';






function Benefits(){
    return(
        <>
            <section className='benefits'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='benefits-header'>
                                <h1> Benefits of Choosing <span> Magic </span>  Building Material Trading  </h1>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partners'>
                            <Swiper  grabCursor={true} slidesPerView={'auto'} navigation={{
                            nextEl: '.next-btn',
                            prevEl: '.prev-btn',
                            }} pagination={true}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay,EffectCoverflow,EffectCube,Navigation,Pagination]}
                            >
                            <SwiperSlide>
                                <img loading='lazy' src={Partner1} alt='ABB' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img loading='lazy' src={Partner2} alt='DUCAB' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img loading='lazy' src={Partner3} alt='HAGER' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img loading='lazy' src={Partner4} alt='LEGRAND' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img loading='lazy' src={Partner5} alt='COMPANY' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img loading='lazy' src={Partner6} alt='OSRAM' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img loading='lazy' src={Partner7} alt='OSRAM' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img loading='lazy' src={Partner8} alt='OSRAM' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img loading='lazy' src={Partner9} alt='OSRAM' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img loading='lazy' src={Partner10} alt='OSRAM' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img loading='lazy' src={Partner11} alt='OSRAM' />
                            </SwiperSlide>
                            
                            

                        </Swiper>

                            </div>
                        </div>

                        <div className='col-lg-7 col-md-7 col-sm-12 col-12'>
                            <div className='benefits-text'>
                                <div className='text'>
                                    <h2> At Magic Building Material Trading </h2>
                                    <p> we take pride in offering a comprehensive range of electrical, plumbing, hardware, and fire fighting items. Our commitment to excellence and customer satisfaction sets us apart, and when you choose us, you'll experience a host of benefits: </p>

                                    <ul>
                                        <li>
                                            <i className="las la-check-double"></i>
                                            Choose Magic Building Material Trading for unparalleled product variety, quality, and customer-centric services
                                        </li>
                                    </ul>
                                </div>

                                <div className='exp'>
                                    <h3> 10 + </h3>
                                    <h4> Services Features </h4>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
                            <div className='benefits-content'>
                                <div className='benefit'>
                                    <h5>  Wide Range of Products:  </h5>
                                    <p>
                                        From electrical components to plumbing accessories, power tools, and hardware items, we have an extensive selection of products to meet all your building and construction needs.
                                    </p>
                                </div>

                                <div className='benefit'>
                                    <h5>  Exceptional Quality Standards  </h5>
                                    <p>
                                        We prioritize quality in every product we offer. Our items are sourced from reputable manufacturers and undergo stringent quality checks to ensure they meet the highest industry standards
                                    </p>
                                </div>

                                <div className='benefit'>
                                    <h5> Competitive Pricing  </h5>
                                    <p>
                                    We believe that premium quality shouldn't come with an exorbitant price tag. Our competitive pricing strategy ensures that you get the best value for your investment without compromising on quality.
                                    </p>
                                </div>


                                <div className='benefit'>
                                    <h5>  One-Stop Solution </h5>
                                    <p>
                                    With our diverse product categories, you can find everything you need under one roof. Say goodbye to the hassle of dealing with multiple suppliers – we've got you covered.
                                    </p>
                                </div>


                                <div className='benefit'>
                                    <h5> Expertise and Guidance </h5>
                                    <p>
                                    Our team of knowledgeable professionals is always ready to assist you. Whether you're looking for product recommendations, technical advice, or assistance with your purchase decisions, we're here to help.
                                    </p>
                                </div>


                                <div className='benefit'>
                                    <h5> Reliability  </h5>
                                    <p>
                                    We've built a reputation for reliability and trustworthiness. When you choose Magic Building Material Trading, you can count on receiving your products on time and as described.
                                    </p>
                                </div>


                                <div className='benefit'>
                                    <h5>  Support for Different Industries </h5>
                                    <p>
                                    Whether you're in construction, maintenance, or any other industry that requires building materials, our diverse product range caters to various sectors, ensuring your specific needs are met.
                                    </p>
                                </div>


                                <div className='benefit'>
                                    <h5>  Brands You Can Trust </h5>
                                    <p>
                                    We collaborate with well-known brands in the industry, assuring you of the authenticity and performance of the products you purchase from us.
                                    </p>
                                </div>



                                <div className='benefit'>
                                    <h5>  Easy Ordering Process </h5>
                                    <p>
                                    Our user-friendly website and ordering process make it convenient for you to browse, select, and purchase the products you need from the comfort of your location.
                                    </p>
                                </div>

                                <div className='benefit'>
                                    <h5> Commitment to Sustainability </h5>
                                    <p>
                                    We're mindful of the environment and strive to offer products that align with sustainability practices, contributing to a greener future.
                                    </p>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default Benefits;