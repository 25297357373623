import './css/social.css';

function Social(){
    return(
        <>
            <section className='social-section'>
                <div className='container-fluid'>
                    <div className='social-content'>
                        <div className='map'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='contact-info'>
                                <div className='info'>
                                    <h2> <i className="las la-map-marked-alt"></i> Our Address (office) </h2>
                                    <p>
                                    Ras Al Khor Industrial Area 3, Al Fahad Building, G01
                                    </p>
                                </div>

                                <div className='info'>
                                    <h2> <i className="las la-envelope"></i> Email </h2>
                                    <p>
                                        info@magicdxb.com
                                    </p>
                                </div>

                                
                                <div className='info'>
                                    <h2> <i className="las la-phone-volume"></i> Phone-Number </h2>
                                    <p>
                                        +971 4267881
                                    </p>
                                </div>

                            </div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d902.661585836877!2d55.3818056!3d25.181416699999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDEwJzUzLjEiTiA1NcKwMjInNTQuNSJF!5e0!3m2!1sar!2seg!4v1692637263181!5m2!1sar!2seg" width="100%" height="450" allowFullScreen="allowfullscreen" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>

                                <div className='contact-info sec'>
                                
                                <div className='info'>
                                    <h2> <i className="las la-map-marked-alt"></i> Our Address (store) </h2>
                                    <p>
                                        Ras Al Khor Industrial Area 3
                                    </p>
                                </div>

                                <div className='info'>
                                    <h2> <i className="las la-envelope"></i> Email </h2>
                                    <p>
                                        info@magicdxb.com
                                    </p>
                                </div>

                                
                                <div className='info'>
                                    <h2> <i className="las la-phone-volume"></i> Phone-Number </h2>
                                    <p>
                                        +971 4267881
                                    </p>
                                </div>

                                </div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.6457952126343!2d55.380782399999994!3d25.1814352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5c3986c58ff1%3A0xc3aaa737c7b539e1!2sMagic%20Building%20Trading%20LLC%20-%20Store!5e0!3m2!1sar!2seg!4v1692637308821!5m2!1sar!2seg" width="100%" height="450" allowFullScreen="allowfullscreen" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                            
                        </div>


                        <div className='social'>
                            <ul>
                                <li>
                                    <a href="/">
                                        <i className="lab la-facebook-f"></i>
                                        Facebook
                                    </a>
                                </li>

                                <li>
                                    <a href="/">
                                        <i className="lab la-twitter"></i>
                                        Twitter
                                    </a>
                                </li>

                                <li>
                                    <a href="/">
                                        <i className="lab la-linkedin-in"></i>
                                        Linkedin
                                    </a>
                                </li>

                                <li>
                                    <a href="/">
                                        <i className="lab la-google-plus-g"></i>
                                        Google +
                                    </a>
                                </li>

                                <li>
                                    <a href="/">
                                        <i className="lab la-instagram"></i>
                                        Instagram 
                                    </a>
                                </li>


                            </ul>
                        </div>
                    </div>
                </div>  
            </section>
        </>
    )
}

export default Social;