import './css/uppernav.css';

import Logo from './images/logo.png';

function UpperNav(){
    return(
        <>
            <div className='upper-nav'>
                <div className='container-fluid'>
                    <div className='upper-nav-content'>
                        <div className='left'>
                            <a href="https://goo.gl/maps/NT7vepSD9nPnPX9b7">
                                <i className="las la-map-marker"></i>
                                Ras Al Khor Industrial Area 3
                            </a>

                            <a href="mailto:info@magicdxb.com">
                                <i className="las la-envelope"></i>
                                info@magicdxb.com
                            </a>
                        </div>

                        <div className='middle'>
                            <div className='logo'>
                                <img src={Logo} alt='Logo' />
                            </div>
                        </div>

                        <div className='right'>
                            <a  aria-label="facebook" href="/">
                                <i className="lab la-facebook-f"></i>
                            </a>
                            <a aria-label="twitter" href="/">
                                <i className="lab la-twitter"></i>
                            </a>
                            <a aria-label="dribble" href="/">
                                <i className="las la-basketball-ball"></i>
                            </a>
                            <a aria-label="google" href="/">
                                <i className="lab la-google-plus-g"></i>
                            </a>
                            <a aria-label="linkedin" href="/">
                                <i className="lab la-linkedin-in"></i>
                            </a>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default UpperNav;