
import ProductPageImg from './images/landingimg.webp';
import Products from './Products';
import Services from './Services';

import { Link as RouterLink } from 'react-router-dom';


function ProductsPage(){
    return(
        <>
            <section className="products-page">
                <div className="container-fluid">
                    <div className='page-header'>
                            <div className='overlay'></div>
                            <img src={ProductPageImg} alt="productspageimg" />

                            <h1> Our Products </h1>
                            <ul>
                                <li>
                                    <RouterLink to="/">
                                        Home
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/our-products">
                                        Products
                                    </RouterLink>
                                </li>
                            </ul>
                        </div>

                        <Services />

                        <Products />

                    
                </div>
            </section>
        </>
    )
}

export default ProductsPage;