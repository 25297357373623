import './css/aboutpage.css';

import AboutPageImg from './images/aboutpageimg.webp';

import AboutPageSmallImg from './images/products/Electricals_1.webp';

import Products from './Products';
import Services from './Services';

import { Link as RouterLink } from 'react-router-dom';


function AboutPage(){
    return(
        <>
            <section className='about-page'>
                <div className='container-fluid'>
                    <div className='page-header'>
                        <div className='overlay'></div>
                        <img src={AboutPageImg} alt="aboutpageimg" />

                        <h1> About </h1>
                        <ul>
                            <li>
                                <RouterLink to="/">
                                    Home
                                </RouterLink>
                            </li>

                            <li>
                                <RouterLink to="/about-us">
                                    About
                                </RouterLink>
                            </li>
                        </ul>
                    </div>


                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-page-img'>
                                <img src={AboutPageImg} alt="aboutpageimg" />
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-page-content'>
                                <h3> About Magic Building Material Trading </h3>
                                <h1> Discover Magic Building Material High Quality Products </h1>
                                <p> we take pride in offering a comprehensive range of electrical, plumbing, hardware, and fire fighting items. Our commitment to excellence and customer satisfaction sets us apart, and when you choose us, you'll experience a host of benefits:</p>

                                <div>
                                    <img src={AboutPageSmallImg} alt='aboutpagesmall' />
                                    <h2>
                                        Choose Magic Building Material Trading for unparalleled product variety, quality, and customer-centric services. Your satisfaction is our priority, and we're dedicated to being your partner in success for all your building material requirements.
                                    </h2>
                                </div>

                                <a aria-label='contact us' href="https://wa.me/00971503938759">
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>

                    <Services />
                    <Products />
                </div>
            </section>
        </>
    )
}

export default AboutPage;