import './css/navbar.css';



import { Link as RouterLink } from 'react-router-dom';
import { useEffect,useRef  } from "react";
import $ from 'jquery';


function Navbar(){



    

    useEffect(() => {
        $("#open-nav").on("click", function() {
            $(".nav-elements").slideDown(400);
            $(".nav-elements").css({display: "flex"});
        });

        $("#close-nav").on("click", function() {
            $(".nav-elements").slideUp(400);
        });

        if ($(window).width() < 950) {
            $(".nav-elements ul li:not(.special)").on("click", function() {
                $(".nav-elements").slideUp(400);
            });
        }

        if ($(window).width() > 950) {
            $(".nav-elements ul li a").on("click", function() {
                $(this).next().toggleClass("active");
            });
        }

        if ($(window).width() < 950) {
            $(".nav-elements ul li a").on("click", function() {
                $(this).next().toggleClass("active");
            });
        }

        if ($(window).width() > 950) {
            $(".nav-elements ul li .inner-eles a").on("click", function() {
                $(".nav-elements ul li .inner-eles").removeClass("active");

            });
        }
        
        if ($(window).width() < 950) {
            $(".nav-elements ul li .inner-eles a").on("click", function() {
                $(".nav-elements ul li .inner-eles").removeClass("active");
                $(".nav-elements").slideUp(400);
            });

        }


    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
        loadingElement.remove();
    }
    }, []);



    return(
        <>
            <nav>
                <div className='container-fluid'>
                    <div className='nav-content'>
                        <button id="open-nav"> <i className="las la-bars"></i> </button>
                        <div className='nav-elements'>
                        <button id="close-nav"> <i className="las la-times"></i> </button>
                            <ul>
                                <li>
                                    <RouterLink to="/">
                                        Home
                                    </RouterLink>
                                    </li>
                                    <li>

                                    <RouterLink to="/about-us">
                                        About
                                    </RouterLink>
                                    </li>
                                    <li>

                                    <RouterLink to="/our-products">
                                        Products
                                    </RouterLink>
                                    </li>
                                    <li>

                                    <RouterLink to="/contact-us">
                                        Contact
                                    </RouterLink>
                                </li>
                            </ul>
                        </div>

                        <div className='contact-info'>
                            <div className='phone'>

                                <i className="las la-phone-volume"></i>

                                <div className='text'>

                                    <h2>PHONE:</h2>
                                    <a href='tel:00971503938759'>
                                        +971 426 7881
                                    </a>

                                </div>
                            </div>

                            <a href="https://wa.me/00971503938759">
                                Send Message <i className="las la-long-arrow-alt-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;