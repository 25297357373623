import './css/products.css';


import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-cube';

import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,EffectCube,EffectCoverflow,Navigation,Pagination} from 'swiper';


import Product1 from './images/products/Electricals_1.webp';
import Product2 from './images/products/BuildingMaterials_0.webp';
import Product3 from './images/products/PlumbingMaterials_4.webp';
import Product4 from './images/products/Fasteners_2.webp';
import Product5 from './images/products/PowerTools_5.webp';
import Product6 from './images/products/PaintingMaterials_3.webp';
import Product7 from './images/products/Tools_7.webp';
import Product8 from './images/products/Safety_6.webp';



function Products(){
    return(
        <>
            <section className='products'>
                <div className='container-fluid'>
                    <div className='header'>
                        <h1> Discover  <span> Magic Building Material  </span> High Quality Products </h1>
                        
                        <div className='buttons'>
                            <button aria-label="Prev Slide Button" id="prev-slide-btn" type="button" className='prev-btn'>
                                <i className="las la-arrow-left"></i>
                            </button>

                            <button aria-label="Next Slide Button" id="next-slide-btn" type="button" className='next-btn'>
                                <i className="las la-arrow-right"></i>
                            </button>
                            
                        </div>
                    </div>

                    <div className='products-content'>

                    <Swiper  grabCursor={true} slidesPerView={'auto'} navigation={{
                            nextEl: '.next-btn',
                            prevEl: '.prev-btn',
                            }} pagination={true}
                            // autoplay={{
                            //     delay: 4000,
                            //     disableOnInteraction: false,
                            // }}
                            modules={[Autoplay,EffectCoverflow,EffectCube,Navigation,Pagination]}
                            >
                                <SwiperSlide>
                                    <div className='product'>
                                        <div className='text'>
                                            <span> 01 </span>
                                            <a href="https://wa.me/00971503938759">
                                                Electricals

                                                <i className="las la-arrow-right"></i>
                                            </a>
                                        </div>

                                        <img src={Product1} loading='lazy' alt='Electricals' />

                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='product'>
                                        <div className='text'>
                                            <span> 02 </span>

                                            <a href="https://wa.me/00971503938759">
                                                Building Materials & Hardware

                                                <i className="las la-arrow-right"></i>
                                            </a>
                                        </div>

                                        <img src={Product2} loading='lazy' alt='BuildingMaterials' />

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className='product'>
                                        <div className='text'>
                                            <span> 03 </span>


                                            <a href="https://wa.me/00971503938759">
                                                Plumbing Materials

                                                <i className="las la-arrow-right"></i>
                                            </a>
                                        </div>

                                        <img src={Product3} loading='lazy' alt='PlumbingMaterials' />

                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='product'>
                                        <div className='text'>
                                            <span> 04 </span>

                                            <a href="https://wa.me/00971503938759">
                                                Fasteners

                                                <i className="las la-arrow-right"></i>
                                            </a>
                                        </div>

                                        <img src={Product4} loading='lazy' alt='Fasteners' />

                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='product'>
                                        <div className='text'>
                                            <span> 05 </span>

                                            <a href="https://wa.me/00971503938759">
                                                Power Tools

                                                <i className="las la-arrow-right"></i>
                                            </a>
                                        </div>
                                        <img src={Product5} loading='lazy' alt='PowerTools' />

                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='product'>
                                        <div className='text'>
                                            <span> 06 </span>

                                            <a href="https://wa.me/00971503938759">
                                                Painting Materials

                                                <i className="las la-arrow-right"></i>
                                            </a>
                                        </div>

                                        <img src={Product6} loading='lazy' alt='PaintingMaterials' />

                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='product'>
                                        <div className='text'>
                                            <span> 07 </span>

                                            <a href="https://wa.me/00971503938759">
                                                Tools

                                                <i className="las la-arrow-right"></i>
                                            </a>
                                        </div>

                                        <img src={Product7} loading='lazy' alt='Tools' />

                                    </div>
                                </SwiperSlide>



                                <SwiperSlide>
                                    <div className='product'>
                                        <div className='text'>
                                            <span> 08 </span>

                                            <a href="https://wa.me/00971503938759">
                                                Safety

                                                <i className="las la-arrow-right"></i>
                                            </a>
                                        </div>

                                        <img src={Product8} loading='lazy' alt='Safety' />

                                    </div>
                                </SwiperSlide>







                        </Swiper>

                    </div>
                </div>
            </section>
        </>
    )
}


export default Products;