import './css/services.css';

import Ser1 from './images/services/ser1_0.webp';
import Ser2 from './images/services/ser2_1.webp';
import Ser3 from './images/services/ser3_2.webp';
import Ser4 from './images/services/ser4_3.webp';


function Services(){
    return(
        <>
            <section className='services'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                <img alt="Electrical" src={Ser1} loading='lazy'/>

                                <div className='text'>
                                    <div className='icon'>
                                        <span className='number'>01</span>
                                        <i className="las la-bolt"></i>
                                    </div>
                                    <h1> Electrical </h1>

                                    <p> All kinds of electrical products | Best Quality Standards | Most competent pricing </p>

                                    <a href="https://wa.me/00971503938759">
                                        KNOW MORE <i className="las la-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                <img alt="Plumbing"  src={Ser2} loading='lazy'/>

                                <div className='text'>
                                    <div className='icon'>
                                        <span className='number'>02</span>
                                        <i className="las la-shower"></i>
                                    </div>
                                    <h1> Plumbing </h1>

                                    <p> All kinds of plumbing accessories | Best Quality Standards | Most competent pricing </p>

                                    <a href="https://wa.me/00971503938759">
                                        KNOW MORE <i className="las la-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>



                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                <img alt="PowerTools" src={Ser3} loading='lazy'/>

                                <div className='text'>
                                    <div className='icon'>
                                        <span className='number'>03</span>
                                        <i className="las la-tools"></i>
                                    </div>
                                    <h1> Power Tools </h1>

                                    <p> All kinds of power tools | Best Quality Standards | Most competent pricing </p>

                                    <a href="https://wa.me/00971503938759">
                                        KNOW MORE <i className="las la-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>




                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                <img alt="Hardware" src={Ser4} loading='lazy'/>

                                <div className='text'>
                                    <div className='icon'>
                                        <span className='number'>04</span>
                                        <i className="las la-wrench"></i>
                                    </div>
                                    <h1> Hardware </h1>

                                    <p> All kinds of hardware accessories | Best Quality Standards | Most competent pricing </p>

                                    <a href="https://wa.me/00971503938759">
                                        KNOW MORE <i className="las la-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </section>
        </>
    )
}

export default Services;