import './css/landing.css';

function Landing(){
    return(
        <>
            <section className='landing'>
                <div className='overlay'></div>
                <div className='landing-content'>
                    <div className='text'>


                        <div className='left'>
                            <h1> 200 </h1>
                            <h2> Supplied Projects </h2>
                        </div>
                        <span className='line'></span>
                        <div className='right'>
                            <h3> Magic Building </h3>
                            <h1> Welcome To Magic Building Material Trading </h1>

                            <p> Magic Building Material Trading is one of the leading companies trading in all types of electrical,plumbing,hardware and fire fighting items. </p>

                            <div className='contact-info'>
                                <a href="https://wa.me/00971503938759">
                                    Send Message <i className="las la-long-arrow-alt-right"></i>
                                </a>
                                <a href="tel:00971503938759">
                                    <i className="las la-phone-volume"></i>
                                </a>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default Landing;