import './css/contact.css';

import ContactImg from './images/contactimg.webp';

function Contact(){
    return(
        <>
            <section className='contact'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='contact-images'>
                                <div className='back-lay'>

                                </div>

                                <img loading='lazy' src={ContactImg} alt='ContactUs' />

                                <div className='contact-numbers'>
                                    <div className='number'>
                                        <h2> 3511 + </h2>
                                        <h3> Happy Clients </h3>
                                    </div>

                                    <div className='number'>
                                        <h2> 10 +  </h2>
                                        <h3> Services Features </h3>
                                    </div>

                                    <div className='number'>
                                        <h2> 12 + </h2>
                                        <h3> Years of Experince  </h3>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                            <div className='contact-form'>
                                <h1> Stay <span> Connected </span> Us </h1>

                                <form>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input type='text' placeholder='Enter your name *' required  />
                                        </div>

                                        <div className='input'>
                                            <input type='email' placeholder='Enter your Email *' required  />
                                        </div>
                                    </div>

                                    <div className='inputs'>
                                        <div className='input'>
                                            <input type='text' placeholder='Enter your Subject *' required  />
                                        </div>
                                        
                                        <div className='input'>
                                            <input type='tel' placeholder='Enter your Phone *' required  />
                                        </div>
                                    </div>

                                    <div className='inputs'>
                                        <div className='input'>
                                            <textarea required placeholder='Type Your Message *'></textarea>
                                        </div>
                                    </div>

                                    <button aria-label="Send Message Form" id="submit-button" type="submit">
                                        Send Message Now <i className="las la-arrow-right"></i>
                                    </button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;