import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useState, useRef } from "react";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import UpperNav from './components/UpperNav';
import Navbar from './components/Navbar';
import Landing from './components/Landing';
import About from './components/About';
import ChooseUs from './components/ChooseUs';
import Services from './components/Services';
import Benefits from './components/Benefits';
import Contact from './components/Contact';
import Products from './components/Products';
import Social from './components/Social';
import Footer from './components/Footer';
import AboutPage from './components/AboutPage';
import ProductsPage from './components/ProductsPage';
import ContactPage from './components/ContactPage';


function App() {

return (
    <div className="App">
                        <UpperNav />
                        <Navbar />
            <Routes>
            

            <Route path="/" element={
                    <>

                        <Landing />
                        <About />
                        <ChooseUs />
                        <Services />
                        <Benefits />
                        <Contact />
                        <Products />
                        <Social />
                        

                    </>
                } />


                
                <Route path="/about-us" element={
                    <>
                        <AboutPage />

                    </>
                } />

                <Route path="/our-products" element={
                    <>
                        <ProductsPage />

                    </>
                } />

                
            <Route path="/contact-us" element={
                    <>
                        <ContactPage />

                    </>
                } />


        </Routes>

        <Footer />
        
    </div>
);
}

export default App;
