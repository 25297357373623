import './css/chooseus.css';

import ChooseUsImg from './images/chooseus.webp';

function ChooseUs(){
    return(
        <>
            <section className='choose-us'>
                <div className='container-fluid'>
                    <div className='row'>
                    <div className='col-lg-7 col-md-7 col-sm-12 col-12'>
                        <div className='choose-us-content'>
                            <h2> 01 </h2>
                            <h1> Why Choose Us? </h1>

                            <ul>
                                <li>
                                    <h3> <i className="las la-check-circle"></i> Quality Standards </h3>
                                    <p> We uphold the highest quality standards across all our product categories. Our commitment to excellence ensures that every item you receive is dependable and resilient </p>
                                </li>

                                <li>
                                    <h3> <i className="las la-check-circle"></i> Competitive Pricing </h3>
                                    <p> We believe that top-notch products should be accessible to all. That's why we offer competitive pricing, allowing you to procure premium products without breaking the bank. </p>
                                </li>

                                <li>
                                    <h3> <i className="las la-check-circle"></i> Wide Selection </h3>
                                    <p> Our comprehensive product range affords you the luxury of choice. From traditional solutions to the latest innovations, you'll find exactly what you need within our catalog. </p>
                                </li>

                                <li>
                                    <h3> <i className="las la-check-circle"></i> Customer-Centric Approach </h3>
                                    <p> Your satisfaction is our priority. We strive to understand your needs and offer personalized solutions that align with your goals. </p>
                                </li>
                            </ul>

                        </div>
                    </div>

                    <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
                        <div className='choose-us-img'>
                            <img alt="ChooseUsImg" src={ChooseUsImg} loading='lazy' />
                        </div>
                    </div>
        
                    </div>
                </div>
            </section>
        </>
    )
}

export default ChooseUs;