import './css/footer.css';

import Logo from './images/logo.png';

import Product1 from './images/products-small/BuildingMaterials_5.webp';
import Product2 from './images/products-small/Electricals_6.webp';
import Product3 from './images/products-small/Fasteners_7.webp';
import Product4 from './images/products-small/PaintingMaterials_0.webp';
import Product5 from './images/products-small/PlumbingMaterials_1.webp';
import Product6 from './images/products-small/PowerTools_2.webp';
import Product7 from './images/products-small/Safety_3.webp';
import Product8 from './images/products-small/Tools_4.webp';

import { Link as RouterLink } from 'react-router-dom';


function Footer(){
    return(
        <>
            <footer>
                <div className='overlay'></div>
                <div className='container-fluid'>

                    <div className='row'>
                        
                    <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                        <div className='footer-content'>
                            <div className='logo'>
                                <img src={Logo} loading='lazy' alt='LogoMagicFixer' />
                            </div>

                            <p> Choose Magic Building Material Trading for unparalleled product variety, quality, and customer-centric services. Your satisfaction is our priority, and we're dedicated to being your partner in success for all your building material requirements. </p>

                            <a href="https://goo.gl/maps/TB7iyJzHA5AsFwoC8">
                                <i className="las la-map-marked-alt"></i>
                                Ras Al Khor Industrial Area 3, Al Fahad Building, G01 (office Address)
                            </a>

                            <a href="https://www.google.com/maps/place/Magic+Building+Trading+LLC+-+Store/@25.1814352,55.3782075,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f5c3986c58ff1:0xc3aaa737c7b539e1!8m2!3d25.1814352!4d55.3807824!16s%2Fg%2F11bbwxlr_h?entry=ttu">
                                <i className="las la-map-marked-alt"></i>
                                Ras Al Khor Industrial Area 3 (Store Address)
                            </a>

                            <a href="tel:00971503938759">
                                <i className="las la-phone-volume"></i>
                                +971 426 7881
                            </a>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                        <div className='footer-content'>
                            <h1> Fast Links </h1>

                            <ul>
                            <li>
                                    <RouterLink to="/">
                                        Home
                                    </RouterLink>
                                    </li>
                                    <li>

                                    <RouterLink to="/about-us">
                                        About
                                    </RouterLink>
                                    </li>
                                    <li>

                                    <RouterLink to="/our-products">
                                        Products
                                    </RouterLink>
                                    </li>
                                    <li>

                                    <RouterLink to="/contact-us">
                                        Contact
                                    </RouterLink>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                        <div className='footer-content'>
                            <h1> Our Services</h1>

                            <ul>
                                <li>
                                    <a href="/">
                                        Electrical
                                    </a>
                                </li>

                                <li>
                                    <a href="/">
                                        Plumbing
                                    </a>
                                </li>

                                <li>
                                    <a href="/">
                                        Power Tools
                                    </a>
                                </li>

                                <li>
                                    <a href="/">
                                        Hardware
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>



                    <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                        <div className='footer-content'>
                            <h1> Our Products</h1>

                            <div className='images'>
                                <img src={Product1} loading='lazy' alt='MagicBuildProduct1' />
                                <img src={Product2} loading='lazy' alt='MagicBuildProduct2' />
                                <img src={Product3} loading='lazy' alt='MagicBuildProduct3' />
                                <img src={Product4} loading='lazy' alt='MagicBuildProduct4' />
                                <img src={Product5} loading='lazy' alt='MagicBuildProduct5' />
                                <img src={Product6} loading='lazy' alt='MagicBuildProduct6' />
                                <img src={Product7} loading='lazy' alt='MagicBuildProduct7' />
                                <img src={Product8} loading='lazy' alt='MagicBuildProduct8' />
                            </div>
                        </div>
                    </div>


                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                        <div className='copy-right'>
                            <h2> Copyright &copy; 2023 Magic Bulding Trading . All Right Reserved </h2>

                            <ul className='social'>
                                <li>
                                    <a aria-label="facebook" id="facebook-footer-link" href="/">
                                        <i className="lab la-facebook-f"></i>
                                    </a>
                                </li>

                                <li>
                                    <a  aria-label="twitter" id="twitter-footer-link" href="/">
                                        <i className="lab la-twitter"></i>
                                    </a>
                                </li>

                                
                                <li>
                                    <a  aria-label="dribble" id="dribble-footer-link" href="/">
                                        <i className="las la-basketball-ball"></i>
                                    </a>
                                </li>

                                <li>
                                    <a  aria-label="google" id="google-footer-link" href="/">
                                        <i className="lab la-google-plus-g"></i>
                                    </a>
                                </li>


                                
                                <li>
                                    <a  aria-label="linkedin" id="linkedin-footer-link" href="/">
                                        <i className="lab la-linkedin-in"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    </div>




                </div>
            </footer>
        </>
    )
}
export default Footer;