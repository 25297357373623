
import ContactPageImg from './images/landingimg.webp';
import Contact from './Contact';
import Social from './Social';

import { Link as RouterLink } from 'react-router-dom';


function ContactPage(){
    return(
        <>
            <section className="products-page">
                <div className="container-fluid">
                    <div className='page-header'>
                            <div className='overlay'></div>
                            <img src={ContactPageImg} alt="contactpageimg" />

                            <h1> Contact Us </h1>
                            <ul>
                                <li>
                                    <RouterLink to="/">
                                        Home
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/contact-us">
                                        Contact Us
                                    </RouterLink>
                                </li>
                            </ul>
                        </div>

                        <Contact />

                        <Social />

                    
                </div>
            </section>
        </>
    )
}

export default ContactPage;